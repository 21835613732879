<template>
  <MainLayout
    isFullScreen
    class="main-layout--mail-validated">
    <img
      :src="heroImage"
      alt=""
      class="mail-validated__hero">

    <Loader
      v-if="isLoading"
      class="mail-validated__loader">
    </Loader>

    <template v-else-if="isEmailValidated">
      <section class="mail-validated__wrapper">
        <header class="mail-validated__header">
          <Heading class="mb-xs">
            {{ $t("artist.mailValidated.congratulations") }}
          </Heading>
          <Paragraph>
            {{
              isArtistUser
                ? $t("artist.mailValidated.created")
                : $t("artist.dashboard.client.mailValidated.subtitle")
            }}
          </Paragraph>
        </header>
        <router-link :to="{ name: isArtistUser ? 'ArtistEmboardingIdentity' : 'ClientEmboardingIdentity' }">
          <Button :isFluid="!$mediaQueries.isDesktop">
            {{
              isArtistUser
                ? $t("artist.mailValidated.fillProfile")
                : $t("artist.dashboard.client.mailValidated.cta")
            }}
          </Button>
        </router-link>
        <LegalLinks
          v-if="$mediaQueries.isDesktop"
          class="mail-validated__legal-links">
        </LegalLinks>
      </section>
    </template>
  </MainLayout>
</template>

<script>

import {
  mapState,
  mapGetters,
  mapMutations,
  mapActions
}                                             from 'vuex';

import MainLayout                             from '../../../components/layout/MainLayout/l-MainLayout.vue';
import Loader                                 from '../../../components/atoms/Loader/a-Loader.vue';
import Heading                                from '../../../components/atoms/Heading/a-Heading.vue';
import Paragraph                              from '../../../components/atoms/Paragraph/a-Paragraph.vue';
import Button                                 from '../../../components/atoms/Button/a-Button.vue';
import LegalLinks                             from '../../../components/molecules/LegalLinks/m-LegalLinks.vue';
import { ARTIST_VALIDATE_EMAIL }              from '../api/queries/q-artist-validate-email.js';
import { ARTIST_VALIDATE_EMAIL_RESPONSE }     from '../api/responses/r-artist-validate-email.js';
import { getDateWithTimezoneOffset }          from '../../../utils/dateUtils.js';
import ClientHeroImage                        from '../../../../public/assets/images/client/email-validated.jpg';


export default {
  name: 'p-MailValidation-Artist',
  components: {
    MainLayout,
    Loader,
    Heading,
    Paragraph,
    Button,
    LegalLinks,
  },
  data: () => ({
    isLoading: false,
    isEmailValidated: true,
  }),
  props: {
    token: {
      type: [String, Boolean],
      required: true,
    },
  },
  computed: {
    ...mapState('User', ['user']),
    ...mapGetters({
      isArtistUser: 'User/IS_ARTIST_USER',
    }),
    currentDateTimestamp() {
      return getDateWithTimezoneOffset();
    },
    heroImage() {
      return ClientHeroImage;
    },
  },
  async created() {
    if (this.token) await this.validateEmail();
  },
  methods: {
    ...mapMutations({
      addErrors: 'ADD_ERRORS',
      setUser: 'User/SET_USER',
      auth: 'User/AUTHENTICATE'
    }),
    ...mapActions({
      fetchMe: 'User/FETCH_ME',
      fetchAvailabilities: 'Availabilities/FETCH_AVAILABILITIES',
    }),
    async validateEmail() {
      try {
        this.isLoading = true;
        this.setUser({ ...this.user, Token: this.token });

        const data = await this.$apollo.mutate({
          mutation: ARTIST_VALIDATE_EMAIL
        });
        const { '__typename': responseTypeName } = data.data.emailValidation;

        switch (responseTypeName) {
          case ARTIST_VALIDATE_EMAIL_RESPONSE.operationInformation:
            this.auth({ ...this.user, Token: this.token });

            await this.fetchMe();

            if (this.isArtistUser) await this.fetchAvailabilities(this.currentDateTimestamp);

            this.isEmailValidated = true;

            break;

          case ARTIST_VALIDATE_EMAIL_RESPONSE.emailAlreadyVerified:
            this.addErrors([{ type: 'warning', message: this.$t('artist.mailValidated.errors.emailAlreadyVerified') }]);
            break;

          case ARTIST_VALIDATE_EMAIL_RESPONSE.unknownError:
            this.addErrors([{ type: 'alert', message: this.$t('common.errors.UnknownError') }]);
            break;

          default:
            break;
        }
      } catch (error) {
        this.addErrors([{ type: 'alert', message: this.$t('common.errors.UnknownError') }]);
      } finally {
        this.isLoading = false;
      }
    }
  },
};

</script>

<style lang="scss">

.mail-validated {
  &__hero {
    grid-row: 1 / 2;
    object-fit: cover;
    max-height: 100%;
    min-width: 100%;

    @media screen and ($desktop) {
      position: fixed;
      left: 0;
      right: 0;
      min-width: 50%;
      max-width: 50%;
      min-height: 100%;
    }
  }

  &__wrapper {
    grid-column: 1 / 2;
    display: grid;
    grid-template-rows: 1fr auto;
    align-items: start;
    padding: var(--space-md);

    @media screen and ($desktop) {
      grid-column: 2 / 3;
      grid-template-rows: 1fr auto 1fr;
      grid-row-gap: var(--space-lg);
      max-width: 800px;
      align-content: center;
      padding: var(--space-xxl);
      margin-bottom: var(--space-xxl);
    }
  }

  &__header {
    @media screen and ($desktop) {
      align-self: end;

      h1 {
        max-width: 80%;
      }
    }
  }

  &__legal-links {
    @media screen and ($desktop) {
      align-self: end;
    }
  }

  &__loader {
    grid-row: 2 / 3;
    align-self: center;

    @media screen and ($desktop) {
      grid-row: 1 / -1;
      grid-column: 2 / 3;
    }
  }
}

</style>
