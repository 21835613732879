import gql      from 'graphql-tag';


export const ARTIST_VALIDATE_EMAIL = gql`
  mutation emailValidation {
    emailValidation {
      __typename

      ... on OperationInformation {
        message
        status
      }

      ... on EmailAlreadyVerified {
        message
        Status
      }

      ... on TokenError {
        message
        status
        requestID
      }
    }
  }
`;